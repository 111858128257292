import Vue from "vue";
import Components from "./components";
import "./app.css";
import "./scss/stilarn.scss";
import "./scss/plyr.scss";
import lazysizes from "lazysizes";
import { gsap, Linear } from "gsap";

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  delimiters: ["${", "}"],
  data() {
    return {
      bollTL: null
    };
  },
  mounted() {
    this.bollTL = gsap.timeline({ paused: true, repeat: -1 });
    this.bollTL.staggerTo(
      [
        "[data-name='super-streck1']",
        "[data-name='super-streck3']",
        "[data-name='super-streck5']"
      ],
      0.25,
      { scaleX: 1.2, transformOrigin: "100% 50%", ease: Linear.easeNone },
      0,
      0
    );
    this.bollTL.staggerTo(
      [
        "[data-name='super-streck1']",
        "[data-name='super-streck3']",
        "[data-name='super-streck5']"
      ],
      0.25,
      { scaleX: 1, transformOrigin: "100% 50%", ease: Linear.easeNone },
      0,
      0.25
    );
    this.bollTL.staggerTo(
      [
        "[data-name='super-streck2']",
        "[data-name='super-streck4']",
        "[data-name='super-streck6']"
      ],
      0.25,
      { scaleX: 0.8, transformOrigin: "100% 100%", ease: Linear.easeNone },
      0,
      0
    );
    this.bollTL.staggerTo(
      [
        "[data-name='super-streck2']",
        "[data-name='super-streck4']",
        "[data-name='super-streck6']"
      ],
      0.25,
      { scaleX: 1, transformOrigin: "100% 100%", ease: Linear.easeNone },
      0,
      0.25
    );
  },
  methods: {
    moveBall() {
      this.bollTL.play();
    },
    stopBall() {
      this.bollTL.pause(0);
    }
  }
});
