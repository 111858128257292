<template>
  <div id="bokamera-embedded"></div>
</template>

<script>
import loadjs from "loadjs";
export default {
  name: "BokaMera",
  props: ["bokaMeraId"],
  mounted() {
    const bokaMeraConf = {
      configuration: {
        company: "4c5d131d-efb0-4f2f-aabe-9de183b44af4",
        bookMethods: ["contact-information", "login", "login-facebook"],
        bookLayout: "service-based",
        timesLayout: "week",
        listingLayout: "rows",
        showNextAvailableTime: "true",
        showCompanySummary: "",
        hideServiceImage: "",
        showEndTimeOnTimeslots: "true",
        selectedService: "",
        bookedTimeSlotText: "Bokad",
        language: "sv",
        topOffset: null,
      },
    };

    if (this.bokaMeraId) {
      bokaMeraConf.configuration.selectedService = this.bokaMeraId;
    }
    window.BOKAMERA = bokaMeraConf;
    loadjs("https://embed.bookmore.com/v2/embed.js");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>